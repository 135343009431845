
import { createApp, h, defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import { URLS } from '@/common/urls-enum'
import { Bin } from '@/types/bin'
import Loading from '@/components/shared/Loading.vue'
import HeaderBar from '@/components/shared/Header.vue'
import MapBinModal from '@/components/map/MapBinModal.vue'

// set outside the Vue reactivity because of zoom error after popup close
let mapElement: any = null

export default defineComponent ({
  name: 'Map',
  components: {
    Loading,
    HeaderBar
  },
  data () {
    return {
      binsWithCoordinates: [] as Bin[],
      markersCoordinates: [] as [number, number][],
      binModal: null as any,

      // map initial settings
      mapCenter: [41.966900078, 2.835891520] as [number, number],
      mapZoom: 2,
      mapOptions: {
        zoomSnap: 0.5,
        maxZoom: 18,
        minZoom: 2,
        animate: true
      },

      // helpers
      urls: URLS,
      activeMapLayer: URLS.MAP_LAYER_STREET,

      // utilities
      loading: false,
      settingListVisible: false
    }
  },
  computed: {
    ...mapGetters(['dashboardAreas', 'operator'])
  },
  mounted() {
    this.setAllData()
  },
  methods: {
    ...mapActions(['getDashboardAreas']),

    async setAllData () {
      this.loading = true
      await this.getDashboardAreas()
      if (this.dashboardAreas.length) this.setBinsWithExistingCoordinates()
      this.loading = false
      this.setZoom()
      this.setMap()
      if (this.dashboardAreas.length) this.setMarkers()
    },

    setBinsWithExistingCoordinates () {
      for (const area of this.dashboardAreas) {
        if (area.bins) {
          const bins = area.bins.filter((bin: Bin) => Boolean(bin.coordinates.latitude))
          this.binsWithCoordinates = this.binsWithCoordinates.concat(bins)
        }
      }
    },

    setZoom () {
      // if there is only 1 single location focus on it
      if (this.binsWithCoordinates.length === 1) {
        this.mapCenter = [
          this.binsWithCoordinates[0].coordinates.latitude,
          this.binsWithCoordinates[0].coordinates.longitude
        ]
        this.mapZoom = 16.5
        // otherwise set bounds in the map
      } else if (this.binsWithCoordinates.length > 1) {
        this.markersCoordinates = this.binsWithCoordinates.map(bin => {
          return [bin.coordinates.latitude, bin.coordinates.longitude]
        })
      }
    },
    setMap () {
      mapElement = L.map('map_wrap').setView(this.mapCenter, this.mapZoom)
      L.tileLayer(URLS.MAP_LAYER_STREET, this.mapOptions).addTo(mapElement)
      if (this.markersCoordinates.length) {
        mapElement.fitBounds(this.markersCoordinates, { padding: [30, 30] })
      }
    },
    setMarkers () {
      let markerCluster = (L as any).markerClusterGroup({
        showCoverageOnHover: false,
        disableClusteringAtZoom: 7,
        spiderfyOnMaxZoom: false
      })
      for (const bin of this.binsWithCoordinates) {
        const marker = L.marker(
          [bin.coordinates.latitude, bin.coordinates.longitude],
          {icon: this.setMarkerIcons()}
        ).bindPopup('', {offset: [0, -20]})
          .addEventListener('click', event => {
            this.setMarkerPopupContent(event, bin)
          }).addEventListener('popupclose', () => {
            this.binModal.unmount()
          })
        markerCluster.addLayer(marker)
      }
      mapElement.addLayer(markerCluster)
    },
    setMarkerIcons (): L.Icon<L.IconOptions> {
      return L.icon({
        iconUrl: require('@/assets/images/map-marker--base-blue.svg'),
        iconSize: [24, 32],
        iconAnchor: [12, 32]
      });
    },
    setMarkerPopupContent (event: any, bin: Bin) {
      const el = document.createElement('div')
      this.binModal = createApp({
        render() {
          return h(MapBinModal)
        }
      }, {bin})
      const mountedBinModal = this.binModal.mount(el)
      event.target._popup.setContent(mountedBinModal.$el)
    },

    changeMapLayer (layer: string) {
      L.tileLayer(layer).addTo(mapElement)
      this.activeMapLayer = layer
    },

    // utilities
    toggleSettingsList () {
      this.settingListVisible = !this.settingListVisible
    }
  }
})


import { defineComponent, PropType } from 'vue'
import BinColorConfig from '@/common/bin-color-config'
import calculations from '@/common/mixins-calculations'
import utilities from '@/common/mixins-utilities'
import { Bin } from '@/types/bin'
import { User } from '@/types/user'
import { translate } from '@/i18n'

export default defineComponent ({
  name: 'MapBinModal',
  mixins: [
    calculations,
    utilities
  ],
  props: {
    bin: {
      type: Object as PropType<Bin>,
      required: true
    }
  },
  data () {
    return {
      operator: JSON.parse(localStorage.getItem('insylo_operator') || '{}') as User
    }
  },
  methods: {
    translate,

    // shortened methods called from mixins
    isBinFullOrEmpty (): string {
      return this.binFullEmpty(
        this.binCurrentVolume(this.bin.data.measurements),
        this.bin.specs.estimatedVolume,
        this.bin.specs.fullThresholdPercent,
        this.bin.specs.emptyThresholdPercent
      )
    },
    binTextColorCss (): string {
      return BinColorConfig(
        this.bin,
        // this.binVolumeRemainingPercentage(this.binCurrentVolume(this.bin.data.measurements), this.bin.specs.estimatedVolume),
        this.binCapacityRemainingPercentage(this.bin.specs.estimatedVolume, this.binRecipe(this.bin.data.stock).density, this.kilosToTonnes(this.binCurrentWeight(this.bin.data.stock)), this.bin.weight.fixedMaxCapacity),

        'binTextColor'
      )
    }
  }
})
